import React from "react";
import styles from "./GetStarted.module.css";

const GetStartedSearch = ({ width = 100, height = 100, style = {} }) => {
    return (
        <svg viewBox="0 0 72 72" width={width} height={height}>
            <path
                className={styles.cls1}
                style={style}
                d="M36.07,71.4c-19.48,0-35.33-15.85-35.33-35.33S16.59,0.74,36.07,0.74S71.4,16.59,71.4,36.07
        S55.55,71.4,36.07,71.4z M36.07,4.34c-17.5,0-31.73,14.23-31.73,31.73S18.57,67.8,36.07,67.8S67.8,53.56,67.8,36.07
        S53.57,4.34,36.07,4.34z"
            />
            <g>
                <path
                    className={styles.cls1}
                    style={style}
                    d="M49.12,52.59L39,42.46l5.31-5.15l10.04,10.04L49.12,52.59z M43.27,42.5l5.85,5.85l0.99-0.99l-5.83-5.83
            L43.27,42.5z"
                />
                <path
                    className={styles.cls1}
                    style={style}
                    d="M33.26,46.24c-8.12,0-14.73-6.61-14.73-14.73s6.61-14.73,14.73-14.73c8.12,0,14.73,6.61,14.73,14.73
            S41.39,46.24,33.26,46.24z M33.26,19.78c-6.47,0-11.73,5.26-11.73,11.73s5.26,11.73,11.73,11.73S45,37.98,45,31.51
            S39.73,19.78,33.26,19.78z"
                />
                <path
                    className={styles.cls1}
                    style={style}
                    d="M44.22,31.6h-3c0-4.45-3.62-8.08-8.08-8.08v-3C39.25,20.53,44.22,25.5,44.22,31.6z"
                />
            </g>
        </svg>
    );
};

export default GetStartedSearch;
