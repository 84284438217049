import React from "react";
import styles from "./GetStarted.module.css";

const GetStartedLearn = ({ width = 100, height = 100, style = {} }) => {
    return (
        <svg viewBox="0 0 72 72" width={width} height={height}>
            <g>
                <path
                    className={styles.cls1}
                    style={style}
                    d="M36.22,72.54c-19.48,0-35.33-15.85-35.33-35.33S16.74,1.88,36.22,1.88s35.33,15.85,35.33,35.33
		S55.7,72.54,36.22,72.54z M36.22,5.48c-17.5,0-31.73,14.23-31.73,31.73c0,17.5,14.23,31.73,31.73,31.73s31.73-14.23,31.73-31.73
		C67.95,19.71,53.72,5.48,36.22,5.48z"
                />
                <g>
                    <path
                        className={styles.cls1}
                        style={style}
                        d="M52.62,44.66c0-1.88-1.35-3.46-3.14-3.8v-5.02c0-0.33-0.23-0.6-0.55-0.66l-0.06-0.01
			c-0.68-0.13-1.37-0.21-2.06-0.24c-1.26-2.42-3.51-4.15-6.11-4.77c2.22-1.34,3.7-3.78,3.7-6.55c0-4.22-3.43-7.65-7.65-7.65
			c-4.22,0-7.65,3.43-7.65,7.65c0,2.78,1.49,5.21,3.7,6.55c-2.6,0.62-4.86,2.35-6.11,4.77c-0.69,0.03-1.38,0.11-2.06,0.24
			l-0.06,0.01c-0.32,0.06-0.55,0.34-0.55,0.66v5.02c-1.79,0.34-3.14,1.91-3.14,3.8c0,1.88,1.35,3.46,3.14,3.8v4.79
			c0,0.2,0.09,0.39,0.24,0.52c0.16,0.13,0.36,0.18,0.56,0.14l0.06-0.01c3.61-0.68,7.35,0.2,10.28,2.41l1.19,0.9
			c0,0,0.01,0,0.01,0.01c0.02,0.01,0.04,0.03,0.06,0.04c0.01,0.01,0.02,0.01,0.03,0.02c0,0,0,0,0.01,0
			c0.03,0.01,0.05,0.02,0.08,0.03c0.01,0,0.02,0,0.02,0.01c0.02,0.01,0.05,0.01,0.07,0.02c0.01,0,0.02,0,0.03,0
			c0.03,0,0.06,0.01,0.1,0.01c0.03,0,0.06,0,0.1-0.01c0.01,0,0.02,0,0.03,0c0.02,0,0.05-0.01,0.07-0.02c0.01,0,0.02,0,0.03-0.01
			c0.03-0.01,0.05-0.02,0.08-0.03c0,0,0,0,0.01,0c0.01-0.01,0.02-0.01,0.03-0.02c0.02-0.01,0.04-0.02,0.05-0.03
			c0,0,0.01,0,0.01-0.01l1.19-0.9c2.93-2.22,6.67-3.1,10.28-2.41l0.06,0.01c0.2,0.04,0.4-0.01,0.56-0.14
			c0.15-0.13,0.24-0.32,0.24-0.52v-4.79C51.27,48.12,52.62,46.55,52.62,44.66z M30.45,23.62c0-3.47,2.83-6.3,6.3-6.3
			c3.47,0,6.3,2.83,6.3,6.3c0,3.47-2.83,6.3-6.3,6.3C33.27,29.92,30.45,27.09,30.45,23.62z M34.94,31.27h3.62
			c2.73,0,5.26,1.42,6.7,3.69c-2.78,0.18-5.48,1.17-7.73,2.88l-0.78,0.59l-0.78-0.59c-2.26-1.71-4.95-2.7-7.73-2.88
			C29.68,32.69,32.21,31.27,34.94,31.27z M22.23,44.66c0-1.39,1.13-2.52,2.52-2.52c0.74,0,1.46,0.19,1.46,2.52
			c0,2.32-0.72,2.52-1.46,2.52C23.36,47.18,22.23,46.05,22.23,44.66z M25.37,52.46v-3.98c2.19-0.4,2.19-2.93,2.19-3.81
			c0-0.88,0-3.41-2.19-3.81v-4.43c3.45-0.52,6.99,0.38,9.79,2.49l0.92,0.7v15.71l-0.1-0.08C32.94,52.95,29.11,51.95,25.37,52.46z
			 M37.53,55.24l-0.1,0.08V39.61l0.92-0.7c2.32-1.76,5.16-2.67,8.03-2.64c0,0,0.01,0,0.01,0c0,0,0.01,0,0.01,0
			c0.58,0.01,1.16,0.05,1.73,0.14v4.43c-2.19,0.4-2.19,2.93-2.19,3.81c0,0.88,0,3.41,2.19,3.81v3.98
			C44.38,51.95,40.55,52.95,37.53,55.24z M48.75,47.18c-0.74,0-1.46-0.19-1.46-2.52c0-2.32,0.72-2.52,1.46-2.52
			c1.39,0,2.52,1.13,2.52,2.52C51.27,46.05,50.14,47.18,48.75,47.18z"
                    />
                    <path
                        className={styles.cls1}
                        style={style}
                        d="M36.75,57.96l-0.38-0.06c-0.05-0.02-0.09-0.03-0.13-0.05l-0.04-0.02c-0.01,0-0.01-0.01-0.02-0.01l-0.19-0.12
			l-1.2-0.91c-2.79-2.11-6.36-2.95-9.8-2.3c-0.43,0.08-0.82-0.02-1.11-0.26c-0.3-0.24-0.46-0.6-0.46-0.98v-4.33
			c-1.85-0.57-3.14-2.3-3.14-4.27c0-1.97,1.29-3.69,3.14-4.27v-4.55c0-0.61,0.44-1.14,1.04-1.25c0.64-0.12,1.25-0.2,1.86-0.24
			c1.15-2.03,2.97-3.59,5.15-4.4c-1.86-1.56-2.97-3.89-2.97-6.33c0-4.55,3.7-8.25,8.25-8.25c4.55,0,8.25,3.7,8.25,8.25
			c0,2.45-1.11,4.78-2.97,6.33c2.17,0.82,4,2.37,5.15,4.4c0.61,0.04,1.21,0.12,1.81,0.23c0.66,0.12,1.1,0.65,1.1,1.26v4.55
			c1.85,0.57,3.14,2.3,3.14,4.27c0,1.97-1.29,3.69-3.14,4.27v4.33c0,0.38-0.17,0.74-0.46,0.98c-0.29,0.24-0.68,0.34-1.05,0.27
			c-3.5-0.66-7.07,0.18-9.86,2.29l-1.54,1.09c-0.1,0.03-0.14,0.04-0.19,0.05C36.86,57.95,36.8,57.96,36.75,57.96z M36.7,56.74
			c0.02,0.01,0.03,0.02,0.04,0.02l0.04-0.02l1.19-0.9c3.06-2.32,6.98-3.24,10.75-2.52l0.12,0l0.03-5.35l0.49-0.09
			c1.54-0.29,2.65-1.64,2.65-3.21c0-1.57-1.12-2.92-2.65-3.21l-0.49-0.09l0-5.51l-0.12-0.09c-0.64-0.12-1.31-0.2-1.97-0.23
			l-0.35-0.01l-0.16-0.31c-1.17-2.25-3.25-3.87-5.72-4.46l-1.43-0.34l1.25-0.76c2.11-1.27,3.41-3.59,3.41-6.04
			c0-3.89-3.16-7.05-7.05-7.05c-3.89,0-7.05,3.16-7.05,7.05c0,2.49,1.28,4.75,3.41,6.04l1.25,0.76l-1.43,0.34
			c-2.47,0.59-4.55,2.21-5.72,4.46l-0.16,0.31l-0.35,0.01c-0.67,0.03-1.33,0.1-1.97,0.23l-0.12,0.09v5.51l-0.49,0.09
			c-1.54,0.29-2.65,1.64-2.65,3.21c0,1.57,1.12,2.92,2.65,3.21l0.49,0.09v5.29l0.2,0.66l-0.06-0.6c3.78-0.71,7.69,0.21,10.75,2.52
			L36.7,56.74z M36.82,56.53V39.31l1.16-0.88c2.4-1.82,5.33-2.78,8.4-2.76c0.63,0.01,1.24,0.06,1.84,0.15l0.51,0.08v5.45l-0.49,0.09
			c-1.51,0.28-1.7,1.87-1.7,3.22c0,1.36,0.19,2.95,1.7,3.22l0.49,0.09v5.17l-0.68-0.09c-3.57-0.48-7.28,0.49-10.16,2.67L36.82,56.53
			z M38.02,39.91v14.25c2.8-1.82,6.17-2.67,9.51-2.37v-2.85c-1.46-0.5-2.19-1.93-2.19-4.28c0-2.35,0.74-3.78,2.19-4.28v-3.44
			c-0.38-0.04-0.76-0.06-1.14-0.07c-2.78-0.04-5.5,0.86-7.68,2.51L38.02,39.91z M36.67,56.53l-1.06-0.8
			c-2.88-2.18-6.58-3.15-10.16-2.67l-0.68,0.09v-5.17l0.49-0.09c1.51-0.28,1.7-1.87,1.7-3.22c0-1.36-0.19-2.95-1.7-3.22l-0.49-0.09
			V35.9l0.51-0.08c3.61-0.54,7.34,0.41,10.24,2.61l1.16,0.88V56.53z M27.26,51.73c2.9,0,5.78,0.85,8.21,2.43V39.91l-0.68-0.52
			c-2.51-1.9-5.7-2.78-8.82-2.45v3.44c1.46,0.5,2.19,1.93,2.19,4.28c0,2.35-0.74,3.78-2.19,4.28v2.85
			C26.4,51.75,26.83,51.73,27.26,51.73z M48.75,47.78c-1.45,0-2.06-0.93-2.06-3.12s0.62-3.12,2.06-3.12c1.72,0,3.12,1.4,3.12,3.12
			C51.87,46.38,50.47,47.78,48.75,47.78z M48.75,42.75c-0.41,0-0.86,0-0.86,1.92c0,1.92,0.46,1.92,0.86,1.92
			c1.06,0,1.92-0.86,1.92-1.92S49.81,42.75,48.75,42.75z M24.74,47.78c-1.72,0-3.12-1.4-3.12-3.12c0-1.72,1.4-3.12,3.12-3.12
			c1.45,0,2.06,0.93,2.06,3.12S26.19,47.78,24.74,47.78z M24.74,42.75c-1.06,0-1.92,0.86-1.92,1.92s0.86,1.92,1.92,1.92
			c0.41,0,0.86,0,0.86-1.92C25.61,42.75,25.15,42.75,24.74,42.75z M36.75,39.18l-1.14-0.86c-2.15-1.63-4.71-2.58-7.41-2.76
			l-1.01-0.07l0.54-0.85c1.56-2.44,4.32-3.96,7.21-3.96h3.62c2.89,0,5.65,1.52,7.21,3.96l0.54,0.85l-1.01,0.07
			c-2.7,0.18-5.26,1.13-7.41,2.76L36.75,39.18z M29.34,34.47c2.54,0.35,4.94,1.34,6.99,2.89l0.42,0.32l0.42-0.32
			c2.05-1.55,4.45-2.54,6.99-2.89c-1.39-1.62-3.45-2.6-5.6-2.6h-3.62C32.79,31.87,30.73,32.85,29.34,34.47z M36.75,30.52
			c-3.8,0-6.9-3.09-6.9-6.9s3.09-6.9,6.9-6.9c3.8,0,6.9,3.09,6.9,6.9S40.55,30.52,36.75,30.52z M36.75,17.92
			c-3.14,0-5.7,2.56-5.7,5.7s2.56,5.7,5.7,5.7c3.14,0,5.7-2.56,5.7-5.7S39.89,17.92,36.75,17.92z"
                    />
                </g>
            </g>
        </svg>
    );
};

export default GetStartedLearn;
