import React from "react";
import Image from "next/legacy/image";

import { imageLoader } from "@/utils/image";

import { LinkButton, LinkText } from "../ui/Button";

const HomeOurValues = () => {
    return (
        <div className="relative w-full min-h-[520px] sm:min-h-[400px] bg-black">
            {/*<img
                src="/images/purple-door-our-values.jpg"
                alt="purple-door-our-values"
                className="absolote top-0 left-0 w-full h-[500px] object-cover object-center"
            />*/}

            <Image
                loader={imageLoader}
                src="https://cdn.purpledoorfinders.com/purpledoorfinders/our-values"
                alt="purple-door-our-values"
                layout="fill"
                objectFit="cover"
                objectPosition="center"
                className="opacity-80"
            />

            <div className="absolute top-0 left-0 w-full text-white">
                <div className="container mx-auto">
                    <h2 className="mt-8 sm:mt-20 text-3xl font-headline font-bold text-center">
                        Our Values.
                    </h2>

                    <h2 className="mt-8 sm:mt-16 text-4xl font-headline font-bold text-center">
                        Compassionate. Comprehensive. Confident.
                    </h2>

                    <p className="mt-8 xl:mt-16 px-8 sm:px-24 text-2xl text-center">
                        Purple Door Finders believes that senior living should improve
                        quality of life.
                    </p>
                    <p className="text-2xl px-8 sm:px-24 text-center">
                        We have created a different way to find the best living and care
                        options that put seniors and their families first.
                    </p>

                    {/*<div className="mt-12 xl:mt-32 flex justify-center">
                        <LinkText href="/about-us" className="px-4 py-2">
                            Read More
                        </LinkText>
                    </div>*/}
                </div>
            </div>
        </div>
    );
};

export default HomeOurValues;
