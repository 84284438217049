import React from "react";
import styles from "./PurpleDoorLogoHorizontal.module.css";

const PurpleDoorLogoRect = ({ width = 400, height = 800, scale = 4 }) => {
    return (
        <svg width={width} height={height}>
            <g
                style={{
                    transform: `scale(${scale}) translate(0, 0)`,
                }}
            >
                <rect
                    x="1.68"
                    y="1.43"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="26.13"
                    y="1.43"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="51.06"
                    y="1.43"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="75.5"
                    y="1.43"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect x="1.68" y="38" className={styles.st2} width="18.68" height="31.72" />
                <rect
                    x="26.13"
                    y="38"
                    className={styles.st0}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="51.06"
                    y="38"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect x="75.5" y="38" className={styles.st2} width="18.68" height="31.72" />
                <rect
                    x="1.68"
                    y="74.87"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="26.13"
                    y="74.87"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="51.06"
                    y="74.87"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="75.5"
                    y="74.87"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="1.68"
                    y="112.01"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="26.13"
                    y="112.01"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="51.06"
                    y="112.01"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
                <rect
                    x="75.5"
                    y="112.01"
                    className={styles.st2}
                    width="18.68"
                    height="31.72"
                />
            </g>
        </svg>
    );
};

export default PurpleDoorLogoRect;
